<template>
<div class="nearly">
    <b-row>
        <b-col><input type="text" v-model="searchQuery" placeholder="Search..." class="search"></b-col>
        <router-link to="/logout.html">Logout</router-link>
    </b-row>
    <b-row v-if="!isMobile()">
        <b-col sm="5" class="scroll">
            <List ref="list" v-on:select-recipe="selectrecipe" :selected="selected" :items="list" />
        </b-col>
        <b-col sm="7" class="scroll" v-on:drop="dropHandler">
            <div class="d-flex justify-content-around">
                <b-button @click="save">Save{{dots}}</b-button>
                <b-button @click="del">Delete</b-button>
                <b-button @click="newr">New</b-button>
            </div>
            <h1><input v-model="recipe.title" /></h1>
            <v-select v-model="recipe.category" :options="cats" taggable :create-option="cat => (cat)" />
            <textarea v-model="recipe.body" rows="30"></textarea>
        </b-col>
    </b-row>
    <b-row v-else>
        <b-col v-if="selected" class="scroll">
            <div v-if="edit">
                <div class="d-flex justify-content-around">
                    <b-button @click="selected = ''"> &lt; Back</b-button>
                    <b-button @click="save">Save{{dots}}</b-button>
                    <b-button @click="newr">New</b-button>
                </div>
                <h3><input v-model="recipe.title" /></h3>
                <v-select v-model="recipe.category" :options="cats" taggable :create-option="cat => (cat)" />
                <textarea v-model="recipe.body"></textarea>
            </div>
            <div v-else>
                <div class="d-flex justify-content-around">
                    <b-button @click="selected = ''"> &lt; Back</b-button>
                    <b-button @click="newr">New</b-button>
                    <b-button @click="edit=true">Edit</b-button>
                </div>
                <h1>{{recipe.title}}</h1>
                <pre>{{recipe.body}}</pre>
            </div>
        </b-col>
        <b-col v-else class="scroll">
            <List ref="list" :selected="selected" :items="list" />
        </b-col>
    </b-row>
</div>
</template>

<script>
import UserInfoStore from '../app/user-info-store'
import axios from 'axios'
import List from './List'
import _ from 'lodash'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    name: 'Home',
    components: {
        List,
        vSelect,
    },
    watch: {
        searchQuery: function (sv) {
            this.recalc(sv)
            this.$refs.list.update()
        },
        recipe: {
            handler: function (newval) {
                let sel = this.selected
                if (sel == '') {
                    sel = this.random()
                }
                if (newval.title != '') {
                    this.recipes[sel] = newval
                    this.selected = sel
                } else {
                    delete(this.recipes, sel)
                }
                setTimeout(function () {
                    let elmnt = document.getElementById(sel)
                    if (elmnt != undefined && elmnt != null) {
                        elmnt.scrollIntoView(true)
                    }
                }, 200)
                this.$refs.list.update()
            },
            deep: true,
        },
    },
    computed: {
        cats() {
            let categorised = _.keyBy(this.recipes, 'category')
            return Object.keys(categorised)
        }
    },
    data() {
        return {
            edit: false,
            searchQuery: '',
            selected: '',
            list: {},
            dots: '',
            recipe: {
                title: '',
                body: '',
                image: '',
            },
            recipes: {
                "loading": {
                    "category": "",
                    "title": "Loading..."
                },
            },
        }
    },
    mounted() {
        this.$root.$on('select-recipe', this.selectrecipe)
        UserInfoStore.load()
        this.load()
        this.recalc(this.searchQuery)
        this.$refs.list.update()
    },
    methods: {
        random() {
            return (new Date().getTime()).toString(16) + "#" + Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 5)
        },
        newr() {
            let index = this.random()
            this.recipe = {
                title: '',
                body: '',
                image: '',
            }
            this.recipes[index] = this.recipe
            console.log(this.recipes)
            this.selected = index
            this.edit = true
            this.$refs.list.update()
        },
        reset() {
            this.searchQuery = ''
            this.recalc('')
        },
        recalc(searchQuery) {
            if (searchQuery) {
                this.list = Object.fromEntries(Object.entries(this.recipes).filter(([k, item]) => {
                    if (k == undefined) return false;
                    if (searchQuery == undefined) {
                        return false
                    }
                    return searchQuery.toLowerCase().split(' ').every(v => {
                        if (item.body == undefined) return item.title.toLowerCase().includes(v)
                        return item.title.toLowerCase().includes(v) || item.body.toLowerCase().includes(v)
                    })
                }))
            } else {
                this.list = this.recipes;
            }
        },
        selectrecipe(index) {
            this.edit = false
            this.selected = index
            this.recipe = this.recipes[index]
            this.recalc(this.searchQuery)
        },
        nl2br(c) {
            if (c[0] == '<') {
                return c
            }
            return c.replace(/\n+/g, "<br/>\n")
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        load() {
            axios.get('https://p4z8g78mya.execute-api.eu-west-2.amazonaws.com/prod/recipes', {
                headers: {
                    'Authorization': UserInfoStore.getToken(),
                    'Content-Type': 'application/json',
                },
            }).then(res => {
                this.recipes = res.data
                this.recalc(this.searchQuery)
                let list = this.$refs.list
                console.log('loaded',list)
                setTimeout(list.update, 200)
            }).catch(err => {
                console.log('error', err)
            })
        },
        del() {
            this.recipe = {
                title: '',
                body: '',
                image: '',
            }
            delete this.recipes[this.selected]
            this.selected = ''
            this.recalc(this.searchQuery)
            this.$refs.list.update()
        },
        save() {
            this.edit = false
            this.dots = '...'
            axios.put('https://p4z8g78mya.execute-api.eu-west-2.amazonaws.com/prod/recipes', this.recipes, {
                headers: {
                    'Authorization': UserInfoStore.getToken(),
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                this.dots = ''
                console.log('return', res)
            }).catch(err => {
                this.dots = '...error'
                console.log('error', err)
            })
        },
        dropHandler(e) {
            let self = this
            if (e.preventDefault != undefined) e.preventDefault()
            let html = e.dataTransfer.getData('text/html')
            if (html != undefined) {
                let d = document.createElement('div')
                d.innerHTML = html
                console.log(d.lastChild.src)
                var img = new Image()
                img.crossOrigin = "anonymous"
                img.onload = function () {
                    let canvas = document.createElement("canvas")
                    canvas.width = 44
                    canvas.height = 44
                    let ctx = canvas.getContext("2d")
                    ctx.drawImage(img, 0, 0, 44, 44)
                    self.recipe['image'] = canvas.toDataURL()
                    self.$refs.list.update()
                }
                img.src = d.lastChild.src
            }
            if (e.dataTransfer.files.length != 0) {
                console.log('Dropped:', e.dataTransfer.files[0])
            }
        },
    },
}
</script>

<style scoped>
.scroll {
    overflow: scroll;
    height: 95vh;
}

.search {
    margin: 5px;
    width: 100%;
}

textarea {
    width: 100%;
    height: 80%;
    font-family: 'Helvetica Neue', Arial, serif;
}

input {
    width: 100%;
}

.nearly {
    width: calc(100vw - 50px);
    margin-left: 10px;
}

pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-family: 'Helvetica Neue', Arial, serif;
}
</style>
