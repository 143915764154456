<template>
<div>
    <div v-for="(recipes,catname) in catd" :key="catname">
        <h5 v-if="catname != 'undefined'">{{catname}}</h5>
        <h5 v-else>Uncategorised</h5>
        <div v-for="recipe in recipes" :key="recipe.key" class="listitem" @click="select(recipe.key)" :id="recipe.key" :class="{'selected': selected==recipe.key}">
            <img v-if="recipe.image" v-bind:src="recipe.image" />
            <img v-else width="44" height="44" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" /> {{recipe.title}}
        </div>
    </div>
</div>
</template>
<script>
import _ from 'lodash'

export default {
    name: 'List',
    data() {
        return {
            catd: [],
        }
    },
    props: {
        items: {
            default: {}
        },
        selected: {
            default: ''
        },
    },
    created() {
        this.catd = this.categorised()
    },
    methods: {
        categorised() {
            const arr = _.map(this.items, (obj, key) => {
                obj.key = key
                return obj
            })
            return _.groupBy(arr, 'category')
        },
        select(index) {
            this.$root.$emit('select-recipe',index)
        },
        update() {
            this.catd = this.categorised()
            this.$forceUpdate()
        },
    },
}
</script>

<style scoped>
.listitem {
    cursor: pointer;
    margin-bottom: 6px;
}

.listitem:hover {
    background-color: grey;
}

h5 {
    background-color: #ddd;
    padding: 5px;
    white-space: nowrap;
}
.selected {
    background-color: lightblue;
}
</style>
